import $ from 'jquery'

export function set_select (element, values, skip_default) {
  if (element.prop('disabled')) {
    return
  }
  element.find('option').remove()
  element.append('<option></option>')
  let keys = Object.keys(values)

  keys.forEach(function (key) {
    element.append(`<option value="${key}">${values[key]}</option>`)
  })
  if (keys.length === 1 && !skip_default) {
    element.val(keys[0]).trigger('change')
  } else {
    // apparently empty works better than null
    element.val('').trigger('change')
  }
}

export function setup_select2_dropdowns () {
  const dft_select2_settings = {allowClear: true, placeholder: '---------', theme: 'bootstrap4', width: '100%'}
  try {
    let $selects = $('select').not('.not-select-2').not('.select2-offscreen').not('[id*=__prefix__]').attr('autocomplete', 'off').not('.select2-heavy')
    $selects.each((i, el) => {
      const $el = $(el)
      const is_required = $("label[for='" + $el.attr('id') + "']").hasClass('required')
      $el.attr('id', $el.attr('id') + i)
      let select2_options = $.extend({}, dft_select2_settings)
      const placeholder = $el.attr('placeholder')
      const allowclear = $el.attr('allow-clear')
      const minimumresultsforsearch = $el.attr('minimumresultsforsearch')
      if (typeof placeholder === 'string' || allowclear === 'off' || is_required || minimumresultsforsearch === '-1') {
        if (placeholder) {
          select2_options.placeholder = placeholder
        } else {
          select2_options.placeholder = ''
        }
        if (allowclear === 'off' || is_required) {
          select2_options.allowClear = false
        }
        // Removes search bar
        if (minimumresultsforsearch === 'Infinity') {
          select2_options.minimumResultsForSearch = minimumresultsforsearch
        }
        $el.select2(select2_options)
      } else {
        $el.select2(dft_select2_settings)
      }
    })
    $selects.each((i, el) => {
      $(el).attr('id', $(el).attr('id').replace(i, ''))
    })
  } catch (e) {
    // this seems to happen occasionally when something has gone wrong, ignore it
  }
}

$(document).on('nav', () => {
  setup_select2_dropdowns()
})
